body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Calendar Overrides */

/* Change highlight and focus to be orange */
button.react-calendar__tile--active {
  background: #fd761c;
}

button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
 background: #fd8535;
}

.react-calendar__tile--hasActive {
  background: #fd761c;
}

button.react-calendar__tile--hasActive:enabled:hover,
button.react-calendar__tile--hasActive:enabled:focus {
 background: #fd8535;
}

/* Change weekends to be black not red */
button.react-calendar__month-view__days__day--weekend {
  color: black;
}

/* Hide year change arrows */
button.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__prev2-button {
  display: none;
}

/* Adjust disabled styles */
button.react-calendar__tile:disabled {
  background-color: initial;
  opacity: 0.4;
}

